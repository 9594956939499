import React, { useEffect, useState } from 'react'

import { Link } from 'gatsby'

import Layout from '../../components/layout'

import bowlinggreen from '../../images/bowlinggreen.jpg'

import '../../styles/common-styles.css'
import '../../styles/music-styles.css'
import '../../styles/layout-styles.css'
import '../../styles/album-styles.css'

const BowlingGreenPage = () => {
  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    let isMobile = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem)
    })

    setIsMobile(isMobile)
  }, [])

  return (
    <Layout>
      <div className='pageTitle'>New Music from Bowling Green</div>
      <hr className='mx-8 mt-2 mb-8 md:my-4 md:mx-64 text-white border-gray-500' />
      {!isMobile && (
        <div className='albumInfoWrapper'>
          <img className='albumInfoImage' src={bowlinggreen} alt='' />
          <div className='albumInfoRight'>
            <div className='albumInfoNotes text-gray-700'>
              <p className='my-4'>
                Velvet's recording of John Williams Tuba Concerto is featured on
                the 3rd volume of "New Music from Bowling Green". Notably, John
                Williams had asked Velvet to make the first recording of the
                piece.
              </p>
              <p className='my-4'>
                <a
                  href='https://www.amazon.com/New-Music-Bowling-Green-Vol/dp/B000QQV8J0'
                  target='_blank'
                  rel='noreferrer'
                >
                  New Music from Bowling Green can be purchased here
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className='mobileAlbumInfoWrapper'>
          <img className='mobileAlbumInfoImage' src={bowlinggreen} alt='' />
          <div className='content text-gray-700'>
            <p className='my-4'>
              Velvet's recording of John Williams Tuba Concerto is featured on
              the 3rd volume of "New Music from Bowling Green". Notably, John
              Williams requested that Velvet make the first recording of the
              concerto.
            </p>
            <p className='my-4'>
              <a
                href='https://www.amazon.com/New-Music-Bowling-Green-Vol/dp/B000QQV8J0'
                target='_blank'
                rel='noreferrer'
              >
                New Music from Bowling Green can be purchased here
              </a>
            </p>
          </div>
        </div>
      )}
      <div
        style={{ marginLeft: '15%', marginTop: '35px', marginBottom: '35px' }}
      >
        <Link to='/music'>←Back to Discography</Link>
      </div>
    </Layout>
  )
}

export default BowlingGreenPage
